import { gql } from '../generated/explorer';

export const getLiquidityBalanceEventsQuery = gql(/* GraphQL */ `
  query getLiquidityBalanceChangeEvents($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      lp: liquidityProviderByAccountId {
        events: liquidityBalanceEventsByLiquidityProviderId(
          orderBy: EVENT_ID_DESC
          first: $first
          offset: $offset
        ) {
          edges {
            node {
              ...LiquidityBalanceEvent
            }
          }
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            startCursor
            hasPreviousPage
          }
        }
      }
    }
  }
`);
