import React from 'react';
import { type ChainflipAsset } from '@chainflip/utils/chainflip';
import { formatUsdValue } from '@chainflip/utils/number';
import classNames from 'classnames';
import { chainLogoMap, type ChainLogoVariant } from '@/shared/assets/chains/logo';
import { tokenLogoMap } from '@/shared/assets/tokens/logo';
import { chainflipAssetMap, getChainflipToken } from '@/shared/utils';
import { chainLogoDimensions, DEFAULT_CHAIN_LOGO_VARIANT } from './ChainLogo';
import { tokenLogoDimensions, type TokenLogoSize } from './TokenLogo';
import { useScreenSize } from '../../hooks';

export const tokenWithChainDimensions = {
  small: {
    container: {
      width: 22,
      minWidth: 22,
      height: 18,
      minHeight: 18,
    },
    token: {
      ...tokenLogoDimensions.small,
      mask: 'radial-gradient(5px at 80% 82%, #1e1e1e9c 24%, rgb(255, 255, 255) 200%)',
    },
    chain: chainLogoDimensions.xsmall,
  },
  medium: {
    container: {
      width: 28,
      minWidth: 28,
      height: 24,
      minHeight: 24,
    },
    token: {
      ...tokenLogoDimensions.medium,
      mask: 'radial-gradient(6px at 80% 82%, rgba(30, 30, 30, 0.61) 24%, rgb(255, 255, 255) 200%)',
    },
    chain: chainLogoDimensions.small,
  },
  large: {
    container: {
      width: 36,
      minWidth: 36,
      height: 32,
      minHeight: 32,
    },
    token: {
      ...tokenLogoDimensions.large,
      mask: 'radial-gradient(7px at 80% 82%, #1e1e1e9c 24%, rgb(255 255 255) 200%)',
    },
    chain: chainLogoDimensions.medium,
  },
} as const satisfies Record<
  TokenLogoSize,
  {
    container: React.HTMLAttributes<HTMLDivElement>['style'];
    token: React.SVGProps<SVGSVGElement>;
    chain: React.SVGProps<SVGSVGElement>;
  }
>;
export type TokenWithChainSize = keyof typeof tokenWithChainDimensions;
export const DEFAULT_COMBO_SIZE: TokenWithChainSize = 'small';

export const TokenWithChain = ({
  chainLogo: ChainLogo,
  tokenLogo: TokenLogo,
  displayChainLogo = true,
  size = DEFAULT_COMBO_SIZE,
}: {
  chainLogo: ((props?: React.SVGProps<SVGSVGElement>) => JSX.Element) | JSX.Element;
  tokenLogo: ((props?: React.SVGProps<SVGSVGElement>) => JSX.Element) | JSX.Element;
  displayChainLogo?: boolean;
  size?: TokenWithChainSize;
}): JSX.Element => (
  <div className="relative" style={tokenWithChainDimensions[size].container}>
    {typeof TokenLogo === 'function' ? (
      <TokenLogo
        {...tokenWithChainDimensions[size].token}
        mask={displayChainLogo ? tokenWithChainDimensions[size].token.mask : undefined}
      />
    ) : (
      TokenLogo
    )}
    {displayChainLogo && (
      <div className="absolute bottom-0 right-0">
        {typeof ChainLogo === 'function' ? (
          <ChainLogo {...tokenWithChainDimensions[size].chain} />
        ) : (
          ChainLogo
        )}
      </div>
    )}
  </div>
);

export const TokenWithChainByAsset = ({
  asset,
  size = DEFAULT_COMBO_SIZE,
  displayChainLogo = true,
  chainVariant = DEFAULT_CHAIN_LOGO_VARIANT,
}: {
  asset: ChainflipAsset;
  size?: TokenWithChainSize;
  displayChainLogo?: boolean;
  chainVariant?: ChainLogoVariant;
}) => {
  const token = getChainflipToken(asset);
  const tokenLogo = tokenLogoMap[token.symbol];
  const chainLogo = chainLogoMap[token.chain.id][chainVariant];

  return (
    <TokenWithChain
      chainLogo={chainLogo}
      tokenLogo={tokenLogo}
      size={size}
      displayChainLogo={displayChainLogo}
    />
  );
};

export const TokenAmountWithChainByAsset = ({
  asset,
  amount,
  usdAmount,
  displayChainLogo = true,
  amountClass = 'text-12 lg:text-16 text-white',
  size = DEFAULT_COMBO_SIZE,
  compact = false,
  disabled = false,
}: {
  asset: ChainflipAsset;
  amount?: string;
  usdAmount?: string | number;
  displayChainLogo?: boolean;
  amountClass?: string;
  size?: TokenWithChainSize;
  compact?: boolean;
  disabled?: boolean;
}) => {
  const { isMobile } = useScreenSize();
  const assetSize = isMobile ? 'small' : size;

  return (
    <div
      className={classNames(
        compact ? 'gap-x-2' : 'gap-x-3 lg:gap-x-4',
        disabled && 'opacity-30',
        'flex items-center',
      )}
    >
      <TokenWithChainByAsset asset={asset} size={assetSize} displayChainLogo={displayChainLogo} />
      {amount && (
        <div className="flex flex-col">
          <span className={classNames('font-normal', amountClass)}>
            {amount} {!compact && chainflipAssetMap[asset].symbol}
          </span>

          {usdAmount !== undefined && (
            <span className="text-[11px] font-normal text-cf-light-2 lg:text-12">
              {formatUsdValue(usdAmount)}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
