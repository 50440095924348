import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { BaseNavbar, Button, Link } from '@/shared/components';
import NavbarDropdown from '@/shared/components/flip-ui-kit/NavbarDropdown';
import { useScreenSize } from '@/shared/hooks';
import {
  HourglassIcon,
  GavelIcon,
  NodeIcon,
  SwapIcon,
  DropletIcon,
  UsersIcon,
  BrokerIcon,
} from '@/shared/icons/small';
import SearchBar from './HomePage/SearchBar';

type NavDropdown = 'validators' | 'swaps' | 'liquidity' | false;

const NavLinks = ({
  activeDropdown,
  setActiveDropdown,
}: {
  activeDropdown: NavDropdown;
  setActiveDropdown: React.Dispatch<React.SetStateAction<NavDropdown>>;
}) => {
  const { isTablet } = useScreenSize();

  return (
    <>
      <NavbarDropdown
        title="Swaps"
        cta={{
          label: 'Swap',
          href: process.env.NEXT_PUBLIC_SWAPPING_URL as string,
        }}
        items={[
          {
            label: 'Swap Stats',
            href: '/swaps',
            icon: <SwapIcon />,
          },
          {
            label: 'Brokers',
            href: '/brokers',
            icon: <BrokerIcon />,
          },
        ]}
        isActive={activeDropdown === 'swaps'}
        onMouseEnter={isTablet ? undefined : () => setActiveDropdown('swaps')}
        onMouseLeave={isTablet ? undefined : () => setActiveDropdown(false)}
        onClick={() =>
          activeDropdown === 'swaps' ? setActiveDropdown(false) : setActiveDropdown('swaps')
        }
      />
      <NavbarDropdown
        title="Liquidity"
        cta={{
          label: 'Provide Liquidity',
          href: process.env.NEXT_PUBLIC_LP_URL as string,
        }}
        items={[
          {
            label: 'Pools',
            href: '/pools',
            icon: <DropletIcon />,
          },
          {
            label: 'Liquidity Providers',
            href: '/lps',
            icon: <UsersIcon />,
          },
        ]}
        isActive={activeDropdown === 'liquidity'}
        onMouseEnter={isTablet ? undefined : () => setActiveDropdown('liquidity')}
        onMouseLeave={isTablet ? undefined : () => setActiveDropdown(false)}
        onClick={() =>
          activeDropdown === 'liquidity' ? setActiveDropdown(false) : setActiveDropdown('liquidity')
        }
      />
      <NavbarDropdown
        title="Validators"
        items={[
          {
            label: 'Auctions',
            href: process.env.NEXT_PUBLIC_STAKING_URL as string,
            target: '_blank',
            icon: <GavelIcon />,
          },
          {
            label: 'Authorities',
            href: '/authorities',
            icon: <NodeIcon />,
          },
          {
            label: 'Authority Sets',
            href: '/authority-sets',
            icon: <HourglassIcon />,
          },
        ]}
        isActive={activeDropdown === 'validators'}
        onMouseEnter={isTablet ? undefined : () => setActiveDropdown('validators')}
        onMouseLeave={isTablet ? undefined : () => setActiveDropdown(false)}
        onClick={() =>
          activeDropdown === 'validators'
            ? setActiveDropdown(false)
            : setActiveDropdown('validators')
        }
      />
      <Link href="/blocks" noPropagate>
        Blocks
      </Link>
      <div>
        <Button
          fullWidth
          iconPos="left"
          icon={<SwapIcon className="mr-1" />}
          onClick={() => window.open(process.env.NEXT_PUBLIC_SWAPPING_URL as string)}
        >
          Swap
        </Button>
      </div>
    </>
  );
};

export const Navbar = (): JSX.Element => {
  const router = useRouter();
  const [activeDropdown, setActiveDropdown] = useState<NavDropdown>(false);

  return (
    <BaseNavbar
      isFixed
      logoHref="/"
      desktopContent={
        <>
          <div className="mr-auto w-full max-w-[400px] transition-[max-width] duration-200 ease-[cubic-bezier(0.23,1,0.32,1)] focus-within:max-w-[512px]">
            {router.pathname !== '/' && <SearchBar />}
          </div>
          <NavLinks activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown} />
        </>
      }
      mobileContent={
        <>
          <SearchBar />
          <NavLinks activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown} />
        </>
      }
      className="border-b border-b-cf-gray-3"
    />
  );
};
