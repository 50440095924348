import { useMemo, useRef } from 'react';

const useRandomIds = (count: number, deps: unknown[]) => {
  const ref = useRef<() => string>();

  if (!ref.current) {
    try {
      crypto.randomUUID();
      ref.current = () => crypto.randomUUID();
    } catch {
      ref.current = () => Math.random().toString(36).substring(2);
    }
  }

  const ids = useMemo(() => Array.from({ length: count }, () => ref.current!()), deps);

  return ids;
};

export default useRandomIds;
