import { useMemo } from 'react';

export const BitcoinTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.942 8.25085C16.1943 6.56116 14.9078 5.65282 13.1487 5.04686L13.7193 2.75796L12.3257 2.41074L11.7701 4.63932C11.4042 4.54805 11.028 4.46194 10.6542 4.37662L11.2138 2.13336L9.82129 1.78613L9.25025 4.07424C8.94708 4.00519 8.64946 3.93694 8.36057 3.86511L8.36215 3.85797L6.44072 3.3782L6.07008 4.86631C6.07008 4.86631 7.10381 5.10321 7.08199 5.1179C7.64628 5.25877 7.74866 5.63218 7.7312 5.92822L7.08119 8.53577C7.12008 8.54569 7.17048 8.55998 7.22604 8.5822L7.07921 8.54569L6.1677 12.1985C6.09865 12.3699 5.92365 12.6271 5.5292 12.5294C5.54309 12.5497 4.5165 12.2767 4.5165 12.2767L3.82483 13.8719L5.63833 14.3239C5.84307 14.3752 6.04532 14.428 6.24535 14.4802C6.37487 14.5139 6.50346 14.5475 6.63119 14.5802L6.0546 16.8957L7.44628 17.243L8.01771 14.9525C8.39747 15.0556 8.76652 15.1509 9.12763 15.2406L8.55858 17.5203L9.95184 17.8676L10.5284 15.5568C12.9042 16.0064 14.6912 15.8251 15.4423 13.6767C16.0483 11.9465 15.4126 10.9485 14.1626 10.2973C15.0729 10.0866 15.7586 9.48776 15.9416 8.25085H15.942ZM12.7581 12.7149C12.3625 14.3028 9.87643 13.6458 8.73994 13.3454C8.63816 13.3185 8.54721 13.2945 8.46956 13.2752L9.23464 10.2081C9.32956 10.2318 9.44552 10.2578 9.57689 10.2873C10.7526 10.5511 13.1622 11.0918 12.7585 12.7149H12.7581ZM9.81308 8.86444C10.7614 9.11743 12.8284 9.66888 13.1885 8.22634C13.5566 6.75057 11.5467 6.30577 10.565 6.08854C10.4547 6.06412 10.3573 6.04257 10.2778 6.02276L9.58412 8.80452C9.64975 8.82087 9.72679 8.84142 9.81308 8.86444Z"
      fill="#FEAB47"
    />
  </svg>
);

export const ChainflipTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => {
  const [paint0Id, paint1Id] = useMemo(() => [crypto.randomUUID(), crypto.randomUUID()], [props]);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7881 3V8.8702C16.7881 9.91379 16.4579 10.9248 15.8619 11.7564C15.6766 12.0173 15.4672 12.2537 15.2337 12.4739L15.2176 12.482H12.4632V7.04392H9.91011H7.33289H7.10738H4.56238L4.59459 7.01131L6.75302 4.98119L7.55034 4.23927C8.45237 3.39135 9.5638 3 10.788 3H16.7881Z"
        fill={`url(#${paint0Id})`}
      />
      <path
        d="M15.2176 12.49L15.2096 12.4982L15.2015 12.5063L15.0566 12.6449L14.5089 13.1667L12.5196 15.0338L12.3263 15.2132C11.4243 16.0529 10.2404 16.5095 9.02425 16.5095H3V13.8842L3.00805 12.4819L3.02416 10.6148C3.03222 9.25328 3.58793 7.96509 4.57049 7.03564H7.1155H7.12355V12.4819H15.2176V12.49Z"
        fill={`url(#${paint1Id})`}
      />
      <defs>
        <linearGradient
          id={paint0Id}
          x1="10.9644"
          y1="2.4209"
          x2="10.174"
          y2="17.3195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48EF8A" />
          <stop offset="0.5458" stopColor="#43B4A6" />
          <stop offset="0.6918" stopColor="#2D796F" />
          <stop offset="0.9662" />
        </linearGradient>
        <linearGradient
          id={paint1Id}
          x1="9.47251"
          y1="17.2486"
          x2="7.39745"
          y2="1.98207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F96" />
          <stop offset="0.6103" stopColor="#FF33AF" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const EthereumTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.92209 2L9.81458 2.36542V12.9691L9.92209 13.0764L14.8442 10.167L9.92209 2Z"
      fill="white"
    />
    <path d="M9.92261 2L5.00049 10.167L9.92261 13.0765V7.92976V2Z" fill="white" />
    <path
      d="M9.92301 14.678L9.86243 14.7519V18.5291L9.92301 18.7061L14.8481 11.77L9.92301 14.678Z"
      fill="white"
    />
    <path d="M9.92212 18.7061V14.678L5 11.77L9.92212 18.7061Z" fill="white" />
    <path d="M9.92224 13.0768L14.8443 10.1674L9.92224 7.93018V13.0768Z" fill="white" />
    <path d="M5 10.1674L9.92204 13.0769V7.93018L5 10.1674Z" fill="white" />
  </svg>
);

export const PolkadotTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0481 5.34193C11.5822 5.34193 12.8258 4.59382 12.8258 3.67097C12.8258 2.74812 11.5822 2 10.0481 2C8.51401 2 7.27039 2.74812 7.27039 3.67097C7.27039 4.59382 8.51401 5.34193 10.0481 5.34193Z"
      fill="#E82A8F"
    />
    <path
      d="M10.0481 17.9328C11.5822 17.9328 12.8258 17.1846 12.8258 16.2618C12.8258 15.3389 11.5822 14.5908 10.0481 14.5908C8.51401 14.5908 7.27039 15.3389 7.27039 16.2618C7.27039 17.1846 8.51401 17.9328 10.0481 17.9328Z"
      fill="#E82A8F"
    />
    <path
      d="M6.1771 7.65423C6.94415 6.28011 6.93917 4.79184 6.16598 4.33011C5.39279 3.86837 4.14419 4.60801 3.37714 5.98214C2.61009 7.35626 2.61507 8.84453 3.38826 9.30626C4.16145 9.768 5.41005 9.02836 6.1771 7.65423Z"
      fill="#E82A8F"
    />
    <path
      d="M16.7178 13.9495C17.4849 12.5754 17.4803 11.0874 16.7076 10.6259C15.935 10.1645 14.6868 10.9044 13.9197 12.2785C13.1527 13.6527 13.1573 15.1407 13.9299 15.6021C14.7026 16.0635 15.9508 15.3236 16.7178 13.9495Z"
      fill="#E82A8F"
    />
    <path
      d="M6.16647 15.6015C6.93966 15.1398 6.94464 13.6515 6.17759 12.2774C5.41054 10.9033 4.16194 10.1636 3.38875 10.6254C2.61556 11.0871 2.61058 12.5754 3.37763 13.9495C4.14468 15.3236 5.39328 16.0633 6.16647 15.6015Z"
      fill="#E82A8F"
    />
    <path
      d="M16.7082 9.30767C17.4809 8.84624 17.4855 7.35823 16.7184 5.9841C15.9514 4.60998 14.7032 3.87008 13.9305 4.33151C13.1579 4.79293 13.1533 6.28094 13.9204 7.65507C14.6874 9.0292 15.9356 9.76909 16.7082 9.30767Z"
      fill="#E82A8F"
    />
  </svg>
);

export const PolygonTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_634_159282)">
      <path
        d="M14.0465 7.41385C13.7521 7.24563 13.3736 7.24563 13.0371 7.41385L10.682 8.80172L9.08382 9.68491L6.77071 11.0728C6.47632 11.241 6.09781 11.241 5.76136 11.0728L3.95292 9.9793C3.65853 9.81108 3.44825 9.47463 3.44825 9.09612V6.99329C3.44825 6.65684 3.61647 6.32038 3.95292 6.1101L5.76136 5.05869C6.05575 4.89046 6.43426 4.89046 6.77071 5.05869L8.57914 6.15216C8.87354 6.32038 9.08382 6.65684 9.08382 7.03535V8.42321L10.682 7.49797V6.06805C10.682 5.73159 10.5137 5.39514 10.1773 5.18486L6.81277 3.2082C6.51837 3.03997 6.13986 3.03997 5.80341 3.2082L2.35478 5.22691C2.01832 5.39514 1.8501 5.73159 1.8501 6.06805V10.0214C1.8501 10.3578 2.01832 10.6943 2.35478 10.9045L5.76136 12.8812C6.05575 13.0494 6.43426 13.0494 6.77071 12.8812L9.08382 11.5354L10.682 10.6102L12.9951 9.26434C13.2895 9.09612 13.668 9.09612 14.0044 9.26434L15.8129 10.3158C16.1073 10.484 16.3175 10.8204 16.3175 11.1989V13.3018C16.3175 13.6382 16.1493 13.9747 15.8129 14.185L14.0465 15.2364C13.7521 15.4046 13.3736 15.4046 13.0371 15.2364L11.2287 14.185C10.9343 14.0167 10.724 13.6803 10.724 13.3018V11.956L9.12588 12.8812V14.2691C9.12588 14.6055 9.29411 14.942 9.63056 15.1523L13.0371 17.1289C13.3315 17.2971 13.71 17.2971 14.0465 17.1289L17.4531 15.1523C17.7475 14.984 17.9578 14.6476 17.9578 14.2691V10.2737C17.9578 9.93725 17.7895 9.60079 17.4531 9.39051L14.0465 7.41385Z"
        fill="#7B43D8"
      />
    </g>
    <defs>
      <clipPath id="clip0_634_159282">
        <rect
          width="16.1497"
          height="14.0889"
          fill="white"
          transform="translate(1.85034 3.12451)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArbitrumTransparentLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => {
  const [clip0Id, clip1Id] = useMemo(() => [crypto.randomUUID(), crypto.randomUUID()], [props]);
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clip0Id})`}>
        <g clipPath={`url(#${clip1Id})`}>
          <path
            d="M3.03564 7.59883V14.2627C3.03564 14.6911 3.26004 15.0787 3.63404 15.2963L9.40716 18.6283C9.77435 18.8391 10.2299 18.8391 10.5971 18.6283L16.3703 15.2963C16.7375 15.0855 16.9687 14.6911 16.9687 14.2627V7.59883C16.9687 7.17044 16.7443 6.78284 16.3703 6.56525L10.5971 3.23329C10.2299 3.0225 9.77435 3.0225 9.40716 3.23329L3.63404 6.56525C3.26684 6.77604 3.04244 7.17044 3.04244 7.59883H3.03564Z"
            fill="#213147"
          />
          <path
            d="M11.257 12.2231L10.4342 14.4807C10.4138 14.5419 10.4138 14.6099 10.4342 14.6779L11.8486 18.5607L13.4874 17.6155L11.5222 12.2231C11.4746 12.1007 11.3046 12.1007 11.257 12.2231Z"
            fill="#12AAFF"
          />
          <path
            d="M12.9094 8.4292C12.8618 8.3068 12.6918 8.3068 12.6442 8.4292L11.8215 10.6868C11.8011 10.748 11.8011 10.816 11.8215 10.884L14.1402 17.2419L15.779 16.2967L12.9094 8.436V8.4292Z"
            fill="#12AAFF"
          />
          <path
            d="M9.99865 3.48514C10.0395 3.48514 10.0803 3.49874 10.1142 3.51914L16.3566 7.12309C16.4314 7.16389 16.4722 7.24549 16.4722 7.32709V14.535C16.4722 14.6166 16.4246 14.6982 16.3566 14.739L10.1142 18.3429C10.0803 18.3633 10.0395 18.3769 9.99865 18.3769C9.95785 18.3769 9.91705 18.3633 9.88305 18.3429L3.64074 14.739C3.56594 14.6982 3.52514 14.6166 3.52514 14.535V7.32029C3.52514 7.23869 3.57274 7.15709 3.64074 7.11629L9.88305 3.51234C9.91705 3.49194 9.95785 3.47834 9.99865 3.47834V3.48514ZM9.99865 2.43115C9.77425 2.43115 9.55666 2.48555 9.35266 2.60115L3.11035 6.2051C2.70915 6.4363 2.46436 6.85789 2.46436 7.32029V14.5282C2.46436 14.9906 2.70915 15.4122 3.11035 15.6434L9.35266 19.2473C9.54986 19.3629 9.77425 19.4173 9.99865 19.4173C10.223 19.4173 10.4406 19.3629 10.6446 19.2473L16.887 15.6434C17.2882 15.4122 17.5329 14.9906 17.5329 14.5282V7.32029C17.5329 6.85789 17.2882 6.4363 16.887 6.2051L10.6378 2.60115C10.4406 2.48555 10.2162 2.43115 9.99185 2.43115H9.99865Z"
            fill="#9DCCED"
          />
          <path
            d="M5.86475 17.2481L6.44261 15.6709L7.59834 16.6295L6.5174 17.622L5.86475 17.2481Z"
            fill="#213147"
          />
          <path
            d="M9.46831 6.80953H7.88394C7.76834 6.80953 7.65954 6.88433 7.61874 6.99313L4.22559 16.2954L5.86436 17.2406L9.60431 6.99313C9.63831 6.89793 9.57031 6.80273 9.47511 6.80273L9.46831 6.80953Z"
            fill="white"
          />
          <path
            d="M12.2426 6.80953H10.6582C10.5426 6.80953 10.4338 6.88433 10.393 6.99313L6.51709 17.6146L8.15587 18.5598L12.3718 6.99313C12.4058 6.89793 12.3378 6.80273 12.2426 6.80273V6.80953Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id={clip0Id}>
          <rect width="18" height="18" fill="white" transform="translate(1.00195 1.93115)" />
        </clipPath>
        <clipPath id={clip1Id}>
          <rect
            width="16.9998"
            height="16.9998"
            fill="white"
            transform="translate(1.49902 2.43115)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SolanaTransparentLogo = ({
  height = 20,
  width = 20,
  ...props
}: React.SVGProps<SVGSVGElement> = {}): JSX.Element => {
  const [clip0Id, paint0Id, paint1Id, paint2Id, paint3Id, paint4Id, paint5Id] = useMemo(
    () => [
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
    ],
    [props],
  );

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clip0Id})`}>
        <path
          d="M4.78144 13.753C4.82996 13.7045 4.89718 13.6757 4.96891 13.6757H17.7383C17.7393 13.6757 17.74 13.6758 17.7406 13.6758C17.7409 13.6763 17.7412 13.6769 17.7416 13.6777C17.7419 13.6786 17.7421 13.6792 17.7422 13.6797C17.7419 13.6801 17.7414 13.6807 17.7407 13.6813L15.2182 16.2038C15.1697 16.2523 15.1025 16.281 15.0308 16.281H2.26134C2.26038 16.281 2.25964 16.281 2.2591 16.2809C2.25881 16.2805 2.25845 16.2799 2.2581 16.2791C2.25776 16.2782 2.25758 16.2776 2.25748 16.2771C2.25782 16.2766 2.25829 16.2761 2.25894 16.2755L4.78144 13.753Z"
          fill={`url(#${paint0Id})`}
          stroke={`url(#${paint1Id})`}
          strokeWidth="0.516658"
        />
        <path
          d="M4.59878 4.15263C4.69936 4.05608 4.83212 3.99976 4.96891 3.99976H17.7383C17.9717 3.99976 18.0883 4.28138 17.9234 4.44632L15.4009 6.96883C15.3043 7.06538 15.1716 7.12171 15.0308 7.12171H2.26134C2.028 7.12171 1.91133 6.84009 2.07627 6.67514L4.59878 4.15263Z"
          fill={`url(#${paint2Id})`}
        />
        <path
          d="M2.25894 6.85781L4.7793 4.33744C4.83469 4.28503 4.90374 4.25808 4.96891 4.25808H17.7383C17.7393 4.25808 17.74 4.25813 17.7406 4.25818C17.7409 4.25861 17.7412 4.25923 17.7416 4.26007C17.7419 4.26089 17.7421 4.26157 17.7422 4.26207C17.7419 4.26248 17.7414 4.26301 17.7407 4.26366L15.2182 6.78616C15.1697 6.83467 15.1025 6.86338 15.0308 6.86338H2.26134C2.26038 6.86338 2.25964 6.86334 2.2591 6.86328C2.25881 6.86286 2.25845 6.86224 2.2581 6.86139C2.25776 6.86057 2.25758 6.85989 2.25748 6.85939C2.25782 6.85898 2.25829 6.85845 2.25894 6.85781Z"
          stroke={`url(#${paint3Id})`}
          strokeOpacity="0.5"
          strokeWidth="0.516658"
        />
        <path
          d="M15.0308 8.93625C15.1025 8.93625 15.1697 8.96495 15.2182 9.01346L17.7407 11.536C17.7414 11.5366 17.7419 11.5371 17.7422 11.5376C17.7421 11.5381 17.7419 11.5387 17.7416 11.5396C17.7412 11.5404 17.7409 11.541 17.7406 11.5414C17.74 11.5415 17.7393 11.5415 17.7383 11.5415H4.96891C4.89718 11.5415 4.82996 11.5128 4.78144 11.4643L2.25894 8.94182C2.25829 8.94117 2.25782 8.94064 2.25748 8.94023C2.25758 8.93973 2.25776 8.93906 2.2581 8.93823C2.25845 8.93739 2.25881 8.93677 2.2591 8.93634C2.25964 8.93629 2.26038 8.93625 2.26134 8.93625H15.0308Z"
          fill={`url(#${paint4Id})`}
          stroke={`url(#${paint5Id})`}
          strokeWidth="0.516658"
        />
      </g>
      <defs>
        <linearGradient
          id={paint0Id}
          x1="16.5185"
          y1="2.49239"
          x2="7.68102"
          y2="19.4197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint1Id}
          x1="1.99902"
          y1="14.9784"
          x2="18.0006"
          y2="14.9784"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DF59F3" />
          <stop offset="1" stopColor="#91B4E0" />
        </linearGradient>
        <linearGradient
          id={paint2Id}
          x1="12.6543"
          y1="0.475435"
          x2="3.8168"
          y2="17.4027"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint3Id}
          x1="1.99902"
          y1="5.56073"
          x2="18.0006"
          y2="5.56073"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4B5E8" />
          <stop offset="0.229137" stopColor="#67C9D7" />
          <stop offset="1" stopColor="#17F3C6" />
        </linearGradient>
        <linearGradient
          id={paint4Id}
          x1="14.5741"
          y1="1.47699"
          x2="5.73661"
          y2="18.4043"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint5Id}
          x1="1.99902"
          y1="10.2389"
          x2="18.0006"
          y2="10.2389"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B293E9" />
          <stop offset="1" stopColor="#6EC6D8" />
        </linearGradient>
        <clipPath id={clip0Id}>
          <rect width="16" height="12.5401" fill="white" transform="translate(2 3.99982)" />
        </clipPath>
      </defs>
    </svg>
  );
};
