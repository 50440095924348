import { formatUsdValue } from '@chainflip/utils/number';
import { type DefaultRawDatum, ResponsivePie } from '@nivo/pie';
import classNames from 'classnames';
import { PieChartTooltip } from './BoostPoolLiquidityBreakdownChart';
import { FancyDollarDisplay, SkeletonLine } from '..';
import QuestionMarkTooltip from '../QuestionMarkTooltip';

export type PieData = DefaultRawDatum & { color: string };

const Chart = ({ data }: { data: PieData[] }) => (
  <div className="flex items-center justify-end [@media(min-width:1400px)]:flex-1">
    <div className="h-36 w-36">
      <ResponsivePie
        fit
        data={data}
        margin={{ top: 1, right: 0, bottom: 1, left: 0 }}
        colors={(d) => d.data.color}
        tooltip={PieChartTooltip}
        innerRadius={0.6}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        borderWidth={2}
        borderColor={{
          from: 'color',
          modifiers: [['brighter', 0.1]],
        }}
        defs={[
          {
            id: 'ResponsivePieChart',
            type: 'linearGradient',
            colors: [
              { offset: 0, color: 'inherit', opacity: 1 },
              {
                offset: 100,
                color: 'inherit',
                opacity: 0.8,
              },
            ],
          },
        ]}
        fill={[{ match: '*', id: 'ResponsivePieChart' }]}
        animate={false}
      />
    </div>
  </div>
);

export const ResponsivePieCard = ({
  title,
  tooltipContent,
  totalValue,
  data,
  loading,
  subValue,
  icon,
  isMobileView = false,
}: {
  title: string;
  tooltipContent: string;
  totalValue: number;
  data: ({ label: string; absoluteValue: number } & PieData)[];
  loading: boolean;
  subValue?: JSX.Element;
  icon?: JSX.Element;
  isMobileView?: boolean;
}) => (
  <div className="cf-card h-full">
    <div
      className={classNames(
        isMobileView ? 'gap-y-6' : 'gap-y-2',
        'flex h-full flex-col text-cf-light-2',
      )}
    >
      <div className="flex items-center gap-x-1 font-aeonikMedium text-14">
        {icon}
        {title}
        <QuestionMarkTooltip content={tooltipContent} />
      </div>
      <div
        className={classNames(
          isMobileView
            ? 'grid grid-flow-row'
            : 'flex h-full flex-col [@media(min-width:1400px)]:flex-row [@media(min-width:1400px)]:justify-between',
          'items-center gap-x-8 gap-y-4',
        )}
      >
        <div
          className={classNames(
            isMobileView && 'row-start-2 !gap-y-2 md:row-start-1',
            'flex w-full flex-col justify-between gap-y-3',
          )}
        >
          <div className="flex flex-col">
            {loading ? (
              <SkeletonLine height={20} width={100} />
            ) : (
              <FancyDollarDisplay value={formatUsdValue(totalValue)} />
            )}
            {subValue ?? <span className="font-aeonikMedium text-12">Total value</span>}
          </div>
          <hr className="w-full border-cf-gray-4" />
          <div className="flex flex-col gap-y-1">
            {data.map(({ absoluteValue, id, color, label }) => (
              <div key={id} className="flex justify-between gap-x-3 text-12 text-cf-light-2">
                <div className="flex items-center gap-x-1 font-aeonikMedium">
                  <div
                    className="h-2.5 w-[3px] rounded-md"
                    style={{
                      backgroundColor: color,
                    }}
                  />
                  {label}
                </div>
                {loading ? (
                  <SkeletonLine width={50} />
                ) : (
                  <span className="text-cf-white">{formatUsdValue(absoluteValue)}</span>
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          className={classNames(
            isMobileView && 'row-start-1 justify-center md:row-start-2',
            'flex grow',
          )}
        >
          {totalValue !== 0 && <Chart data={data} />}
        </div>
      </div>
    </div>
  </div>
);
